import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { MasterCodeMaintenanceProvider } from "presentation/store/MasterCode/MasterCodeMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { MasterCodeMasterView } from "presentation/view/section/MasterCode/MasterCodeMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const MasterCodeContMain = () => {
    return <ANAInfoWrapper permission={Permission.MASTER_CODE_MAINTENANCE}>
        <MessageBarWrapper>
            <MasterCodeMaintenanceProvider>
                <GridStyles/>
                <MasterCodeMasterView/>
            </MasterCodeMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default MasterCodeContMain;