
import { CodeMaintenanceEntity, EMPTY_CODE_MAINTENANCE_ENTITY } from "domain/entity/MasterData/MasterDataEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface TariffCodeDropdownOptions {
    masterCodeTypeDropdownOptions: DropdownProps[],
    chargeIndDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: CodeMaintenanceEntity,
}

export interface MasterCodeMaintenanceModel {
    isLoading: boolean,
    tableData: CodeMaintenanceEntity[],
    currentSelectedRow: CodeMaintenanceEntity,
    selectedRows: CodeMaintenanceEntity[],
    dynamicOptions: TariffCodeDropdownOptions,
    masterState: MasterChangeState,
    isBackMaster: boolean,
    isShowEditPanel: boolean,
}

export const EMPTY_MASTER_CODE_MAINTENANCEANY_MODEL: MasterCodeMaintenanceModel = {
    isLoading: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_CODE_MAINTENANCE_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        masterCodeTypeDropdownOptions: [],
        chargeIndDropdownOptions: []
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_CODE_MAINTENANCE_ENTITY,
    },
    isBackMaster: false,
    isShowEditPanel: false,
}