
import { useMasterCodeMaintenanceTracked } from "presentation/store/MasterCode/MasterCodeMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import MasterCodeMaintenance from "./MasterCodeMaintenance";
import { MasterCodeMaintenanceFormPanel } from "./MasterCodeMaintenanceFormPanel";

export const MasterCodeMasterView: React.FC = () => {
    const [masterCodeState] = useMasterCodeMaintenanceTracked();
    const { isShowEditPanel } = masterCodeState;

    return <>
        <div className="main-comp-wrapper im-hide-side-form-draggable">
            <MasterCodeMaintenance/>
            {
                (isShowEditPanel && 
                <SliderPanel
                    isOpen={false}
                    leftSectionWidth={"100%"}
                    rightSectionWidth={"0%"}
                    leftChildren={<MasterCodeMaintenanceFormPanel/>}
                />
                )
            }
        </div>
    </>
}