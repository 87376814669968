import { masterCodeDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { MasterCodeMaintenanceConstant } from "presentation/constant/MasterCode/MasterCodeMaintenanceConstant";
import { useMasterCodeMaintenanceVM } from "presentation/hook/MasterCode/useMasterCodeMaintenanceVM";
import { useMasterCodeMaintenanceTracked } from "presentation/store/MasterCode/MasterCodeMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import MasterCodeEditPanelTitleBar from "./MasterCodeEditPanelTitleBar";

export const MasterCodeMaintenanceFormPanel = () => {
    const [masterCodeState] = useMasterCodeMaintenanceTracked();
    const masterCodeVM = useMasterCodeMaintenanceVM();
    const { currentSelectedRow, masterState , dynamicOptions} = masterCodeState;
    const { editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;
    const SCREEN_CONSTANT = MasterCodeMaintenanceConstant;


    const currentEntity = isRead ? currentSelectedRow : editingEntity;

    const memoMasterCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentEntity?.masterCode || ''}
                fieldValue={currentEntity?.masterCode}
                fieldLabel={SCREEN_CONSTANT.CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'masterCode'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterCodeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, currentEntity?.masterCode, SCREEN_CONSTANT.CODE, isSaveClicked, masterCodeVM])

    const memoMasterDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "390px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.masterDesc || ''}
                fieldValue={currentEntity?.masterDesc}
                fieldLabel={SCREEN_CONSTANT.CODE_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'masterDesc'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterCodeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.masterDesc, SCREEN_CONSTANT.CODE_DESC, isSaveClicked, masterCodeVM])

    const memoMasterCodeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentEntity?.masterType || ''}
                fieldValue={currentEntity?.masterType || ''}
                fieldLabel={SCREEN_CONSTANT.CODE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'masterType'}
                isShowMissingError={true}
                maxLength={60}
                options={masterCodeDropdownOption.filter(e => e.value !== 'Country Code')}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterCodeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, currentEntity?.masterType, SCREEN_CONSTANT.CODE_TYPE, isSaveClicked, masterCodeVM])

    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === 'Y'}
                    disabled={!(isEditable || isAdd)}
                    onChange={(e) => masterCodeVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isAdd, isEditable, masterCodeVM])

    const memoUcInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"ucInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.UC_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.ucInd === 'Y'}
                    disabled={isRead}
                    onChange={(e) => masterCodeVM.onCheckboxChange(e.checked, "ucInd")}
                />
            </div>
        </>
        , [SCREEN_CONSTANT.UC_IND, currentEntity?.ucInd, isRead, masterCodeVM])

    const memoChargeInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.chargeInd || ''}
                fieldValue={currentEntity?.chargeInd || ''}
                fieldLabel={SCREEN_CONSTANT.CHARGE_IND}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeInd'}
                isShowMissingError={true}
                maxLength={60}
                options={dynamicOptions.chargeIndDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterCodeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.chargeInd, SCREEN_CONSTANT.CHARGE_IND, isSaveClicked, dynamicOptions.chargeIndDropdownOptions, masterCodeVM])

    const memoEditPanelTitleBar = useMemo(() => {
        return <MasterCodeEditPanelTitleBar />
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            <SidebarTitle>{!isAdd ? currentEntity.masterCode : "NEW CODE"}</SidebarTitle>

                            {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>


                    <CriteriaItemContainer>
                        {memoMasterCodeType}
                        {memoMasterCode}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoMasterDesc}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {
                            currentEntity.masterType === "Service Code" ? memoChargeInd : <></>
                        }

                        {
                            currentEntity.masterType === "Special Ind" ? memoUcInd : <></>
                        }
                        
                        {memoActiveInd}
                    </CriteriaItemContainer>

                </div>
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
