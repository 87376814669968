import { SelectionChangedEvent } from "ag-grid-community";
import { CodeMaintenanceEntity } from "domain/entity/MasterData/MasterDataEntity";
import _ from "lodash";
import { INITIAL_MASTER_CODE_MAINTENANCE_COL_DEF } from "presentation/constant/MasterCode/MasterCodeMaintenanceColumnDefinition";
import { MasterCodeMaintenanceConstant } from "presentation/constant/MasterCode/MasterCodeMaintenanceConstant";
import { useMasterCodeMaintenanceVM } from "presentation/hook/MasterCode/useMasterCodeMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useMasterCodeMaintenanceTracked } from "presentation/store/MasterCode/MasterCodeMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef } from "react";


const MasterCodeTablePanel: React.FC = () => {
    const [masterCodeState] = useMasterCodeMaintenanceTracked();
    const masterCodeVM = useMasterCodeMaintenanceVM();
    const gridRef: any = useRef(null);
    const rightClickRef: any = createRef();
    const { tableData, selectedRows } = masterCodeState;
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const messageBarVM = useMessageBarVM();

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        masterCodeVM.updateSelectedRows(tableData, selectedRows);
    }, [masterCodeVM, tableData])

    const handleAddClick = useCallback(() => {
        masterCodeVM.onAdd();
    }, [masterCodeVM])

    const handleRowDoubleClick = useCallback((entity: CodeMaintenanceEntity) => {
        masterCodeVM.onEdit(entity);
    }, [masterCodeVM])

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, selectedRows])

    const memoTariffCodeTable = useMemo(() => {
        return (
            <NbisTable
                id='tariff-code-table'
                headerLabel={MasterCodeMaintenanceConstant.TITLE}
                isNewColumnSetting={true}
                columns={INITIAL_MASTER_CODE_MAINTENANCE_COL_DEF}
                data={tableData ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAddClick}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: CodeMaintenanceEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        );
    }, [tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
        {memoTariffCodeTable}
    </TableWrapper>
    </>;
}

export default memo(MasterCodeTablePanel);
