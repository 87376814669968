import { MasterCodeAlias } from 'domain/entity/MasterData/MasterDataEntity';
import * as yup from 'yup';


export const commonMasterCodeMaintenanceValidation: { [x: string]: any; } = {
  masterCode: yup.string().required("Missing"),
  masterType: yup.string().required("Missing").oneOf(MasterCodeAlias),
};

export const createMasterCodeMaintenanceValidation: { [x: string]: any; } = {
  ...commonMasterCodeMaintenanceValidation,
};

export const createServiceCodeValidation: { [x: string]: any; } = {
  ...commonMasterCodeMaintenanceValidation,
  chargeInd: yup.string().required("Missing"),
};

export const createMasterCodeMaintenanceValidationSchema = yup.object().shape(createMasterCodeMaintenanceValidation);

export const createServiceCodeValidationSchema = yup.object().shape(createServiceCodeValidation);

