import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { useMasterCodeMaintenanceTracked } from "presentation/store/MasterCode/MasterCodeMaintenanceProvider";
import { MasterCodeMaintenanceVM } from "presentation/viewModel/MasterCode/MasterCodeMaintenanceVM";
import { useMemo } from "react";

export const useMasterCodeMaintenanceVM = () => {
    const [, setMasterCodeMaintenanceState] = useMasterCodeMaintenanceTracked();
    const masterCodeMainVM = useMemo(() =>
        MasterCodeMaintenanceVM({
            dispatch: [setMasterCodeMaintenanceState],
            repo: MasterDataRepoImpl(),
        }), [setMasterCodeMaintenanceState])

    return masterCodeMainVM
}

