import { EMPTY_MASTER_CODE_MAINTENANCEANY_MODEL } from "presentation/model/MasterCode/MasterCodeMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: MasterCodeMaintenanceProvider,
    useTracked: useMasterCodeMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_CODE_MAINTENANCEANY_MODEL), {concurrentMode: true});
export { MasterCodeMaintenanceProvider, useMasterCodeMaintenanceTracked };

