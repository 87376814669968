
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CodeMaintenanceEntity, EMPTY_CODE_MAINTENANCE_ENTITY, MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import _ from "lodash";
import { createMasterCodeMaintenanceValidationSchema, createServiceCodeValidationSchema } from "presentation/constant/MasterCode/MasterCodeMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { MasterCodeMaintenanceModel } from "presentation/model/MasterCode/MasterCodeMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface MasterCodeMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<MasterCodeMaintenanceModel>> | ((value: SetStateAction<MasterCodeMaintenanceModel>) => void),
    ],
    repo: MasterDataRepository,
}

export const MasterCodeMaintenanceVM = ({dispatch,repo}:MasterCodeMaintenanceVMProps) => {
    const [masterCodeMainDispatch] = dispatch;
        
    const loadDropdownOption = async () => {
        await repo.getMasterDataByKey(MasterDataType.CHARGE_IND).then(
            chargeInds => {
                const chargeIndDropdownOptions = chargeInds?.map((chargeInd)=>({
                    dropdownLabel: chargeInd.code,
                    tagLabel: chargeInd.code,
                    value: chargeInd.code,
                })) ?? []

                masterCodeMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeIndDropdownOptions: [ 
                            ...chargeIndDropdownOptions
                        ]
                    }
                }))
            }           
        )
    }

    const onSearch = async () => {
        const entities = await repo.getMasterDataForCodeMaintenance();

        masterCodeMainDispatch(prevState =>({
            ...prevState,
            tableData: entities,
            selectedRows: [],
            currentSelectedRow: EMPTY_CODE_MAINTENANCE_ENTITY,
            isBackMaster: false,
        }))
    }

    const updateSelectedRows = async (allRows:CodeMaintenanceEntity[], selecedRows:CodeMaintenanceEntity[]) => {
        masterCodeMainDispatch(prevState => {
             
            return {
                ...prevState,
                tableData: [...allRows],
                selectedRows: selecedRows,
            }
        })
    }

    const onAdd = () => {
        masterCodeMainDispatch(prevState => {
             
            return {
                ...prevState,
                isShowEditPanel: true,
                masterState:{
                    ...prevState.masterState,
                    isAdd: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: EMPTY_CODE_MAINTENANCE_ENTITY,
                }
            }
        })
    }

    const onEdit = (currentEntity: CodeMaintenanceEntity)=>{
        masterCodeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                isShowEditPanel: true,
                masterState:{
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: currentEntity.masterType === 'Country Code',
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onEditClick = (currentEntity: CodeMaintenanceEntity)=>{
        masterCodeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                isShowEditPanel: true,
                masterState:{
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: true,
                    isRead: currentEntity.masterType === 'Country Code',
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onReset = ()=>{
        masterCodeMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_CODE_MAINTENANCE_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }

    const onClose = () => {
        masterCodeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: EMPTY_CODE_MAINTENANCE_ENTITY,
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: EMPTY_CODE_MAINTENANCE_ENTITY,
                    allFormState:{}
                },
                isBackMaster: true,
                isShowEditPanel: false,
            }
        })
    }

    const onSaveClicked = () => {
        masterCodeMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSave = async (currentEntity: CodeMaintenanceEntity,isAdd: boolean) => {
        let valResult;
        if(currentEntity.masterType === 'Service Code'){
            valResult = await Validation(createServiceCodeValidationSchema).ValidateFormOnly(currentEntity) ;
        }else{
            valResult = isAdd? await Validation(createMasterCodeMaintenanceValidationSchema).ValidateFormOnly(currentEntity) : null;
        }
        let validatedResult: {[x: string]: string} = {};  
        if (valResult) {
            validatedResult = {...valResult, warningMessage: 'Please input the missing value.' }; 

            masterCodeMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }                        
                }
            });

            const res: ResponseEntity={
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }
        if(isAdd){
            return await repo.saveNewMasterData(currentEntity);
        }else{
            return await repo.replaceMasterData(currentEntity);
        }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        masterCodeMainDispatch(prevState => ({
            ...prevState,
            masterState:{
                ...prevState.masterState,
                editingEntity : {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked?"Y":"N",
                }
            }
        }))
    }

    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        if(fieldKey === 'masterCode'){
            val = val.toUpperCase().replace(/\s+/g, '');
        }

        masterCodeMainDispatch(prevState => {
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState:{
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }


    return {
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
        onEditClick: onEditClick,
    }
}