export const masterCodeMaintenanceRequiredFieldList: string[] = [
    'masterCode',
];

export const MasterCodeMaintenanceConstant = {
    CATEGORY: "CODE",
    TITLE: "Code Maintenance",
    CODE: 'Code',
    CODE_DESC: 'Code Desc',
    CODE_TYPE: 'Code Type',
    ACTIVE_IND: 'Active Ind.',
    UC_IND: 'UC Ind.',
    CHARGE_IND: 'Charge Ind.',
}
